//
// Google font - Roboto
//

//@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');

@font-face {
  font-family: "Roboto Light";
  font-style: normal;
  font-weight: 300;
  src: url('../../../fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 400;
  src: url('../../../fonts/Roboto-Medium.ttf') format('truetype');
}